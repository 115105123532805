import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
// import {
//   NbAuthComponent,
//   NbLoginComponent,
//   NbLogoutComponent,
//   NbRegisterComponent,
//   NbRequestPasswordComponent,
//   NbResetPasswordComponent,
// } from '@nebular/auth';

export const routes: Routes = [
  {
    //if path is views go to views/views.module to show routes of views
    path: 'views',
    loadChildren: () => import('../views/views.module')
      .then(m => m.ViewsModule),
  },
  {
    //if path is auth
    path: 'auth',
    // component: NbAuthComponent,
    loadChildren: () => import('../views/auth/auth.module')
      .then(m => m.AuthModule),
  },
  // children: [
  //   {
  //     //if path after auth is empty going to this component (NbLoginComponent)
  //     path: '',
  //     // component: NbLoginComponent,
  //     component: LoginComponent,
  //   },
  //   {
  //     path: 'login',
  //     // component: NbLoginComponent,
  //     component: LoginComponent,
  //   },
  //   {
  //     path: 'register',
  //     component: NbRegisterComponent,
  //   },
  //   {
  //     path: 'logout',
  //     component: NbLogoutComponent,
  //   },
  //   {
  //     path: 'request-password',
  //     component: NbRequestPasswordComponent,
  //   },
  //   {
  //     path: 'reset-password',
  //     component: NbResetPasswordComponent,
  //   },
  // ],
  // },
  // redirect to path if remove any thing after site base url
  // { path: '', redirectTo: 'views', pathMatch: 'full' },
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  // redirect to path if typing wrong path
  { path: '**', redirectTo: 'views' },
];

const config: ExtraOptions = {
  useHash: false,
  onSameUrlNavigation: 'reload',
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
