import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, Provider } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ThemeModule } from '../@theme/theme.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import {
  NbDatepickerModule,
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbTimepickerModule,
  NbToastrModule,
  NbWindowModule,
} from '@nebular/theme';
import { CommonModule } from '@angular/common';
import { TokenInterceptor } from 'src/common/interceptors/authentication.interceptor';
import { NbAuthModule, NbPasswordAuthStrategy } from '@nebular/auth';
import { NbRoleProvider, NbSecurityModule } from '@nebular/security';
import { of } from 'rxjs';
import { AuthGuard } from 'src/views/auth/guards';

export class NbSimpleRoleProvider extends NbRoleProvider {
  getRole() {
    // here you could provide any role based on any auth flow
    return of('guest');
  }
}
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbTimepickerModule.forRoot({
      format: "HH:MM"
    }),
    // NbChatModule.forRoot({
    //   messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    // }),
    ThemeModule.forRoot(),
    CommonModule,
    AppRoutingModule,
  ],
  providers: [
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    NbSecurityModule.forRoot({
      accessControl: {
        guest: {
          view: '*',
        },
        user: {
          parent: 'guest',
          create: '*',
          edit: '*',
          remove: '*',
        },
      },
    }).providers as Provider[],
    {
      provide: NbRoleProvider, useClass: NbSimpleRoleProvider,
    },
    ...NbAuthModule.forRoot({

      strategies: [
        // NbDummyAuthStrategy.setup({
        //   name: 'email',
        //   delay: 3000,
        // }),
        NbPasswordAuthStrategy.setup({
          name: 'email',

          login: {
            redirect: {
              success: '/views/',
              failure: null, // stay on the same page
            },
          },

          register: {
            redirect: {
              success: '/welcome/',
              failure: null, // stay on the same page
            },
          }
        }),
      ]
    }).providers as Provider[],
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
