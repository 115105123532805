import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';

// import { consts, routes } from '../../../consts';
// import { CommonService } from 'src/app/common/services/common.service';

@Injectable()
export class AuthGuard implements CanActivate {
  // public routers: typeof routes = routes;

  // private commonService: CommonService
  constructor(private router: Router) {
  }

  UserViews = ["/views/contracts", "/views/attendance/create/upload-file", "/views/leaves", "/views/holidays", "/views/overtimes"];

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    var token = localStorage.getItem('token');
    var user = JSON.parse(localStorage.getItem('user')!);
    if (token) {
      if (user.role_name == "USER") {
        if (this.UserViews.includes(state.url)) {
          return true;
        } else {
          this.router.navigate(['/views/contracts']);
        }
      }
    } else {
      this.router.navigate(['/auth/login']);
    }
    return true;
  }
}
